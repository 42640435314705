import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Buefy from 'buefy';
import VueMask from 'v-mask';
import Vue from 'vue';
import Hotjar from 'vue-hotjar';

import App from './App.vue';
import http from './config/http';
import currentLocaleMixin from './mixins/currentLocale';
import gtmEventsMixin from './mixins/gtmEvents';
import windowSizeBreakpointsMixin from './mixins/windowSizeBreakpoints';
import router from './router';
import store from './store';
import 'buefy/dist/buefy.css';
import 'lodash';

import i18n from '@/i18n';

Object.defineProperty(Vue.prototype, '$http', { value: http });

Vue.config.productionTip = false;
Vue.mixin(windowSizeBreakpointsMixin);
Vue.mixin(currentLocaleMixin);
Vue.mixin(gtmEventsMixin);

library.add(fas);
Vue.component('VueFontawesome', FontAwesomeIcon);
Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
});

Vue.use(Hotjar, {
  id: '2745681',
  isProduction: process.env.NODE_ENV === 'production',
});

Vue.use(VueMask);

new Vue({
  store,
  i18n,
  router,
  render: (h) => h(App),
}).$mount('#app');
