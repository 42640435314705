<template>
  <div class="language">
    <img :src="icons[$i18n.locale]" :alt="$t('shared.image_alt.flag')" class="language__img" />
    <ul class="language__dropdown dropdown">
      <li v-for="locale in locales" :key="locale" class="dropdown__item" @click="switchLocale(locale)">
        <img :src="icons[locale]" :alt="$t('shared.image_alt.flag')" class="dropdown__img" />
      </li>
    </ul>
  </div>
</template>

<script>
import _ from 'lodash';
import brIcon from 'svg-country-flags/svg/br.svg';
import enIcon from 'svg-country-flags/svg/us.svg';
export default {
  data() {
    return {
      icons: {
        en: enIcon,
        pt: brIcon,
      },
    };
  },

  computed: {
    locales() {
      return _.without(this.$i18n.availableLocales, this.currentLocale);
    },
  },

  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.language {
  max-width: 40px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  &:hover {
    .language__dropdown {
      opacity: 1;
      visibility: visible;
    }
  }
  &__img {
    max-width: 25px;
    width: 100%;
    height: 45px;
    cursor: pointer;
  }

  &__dropdown {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    width: 80px;
    top: 60px;
    background-color: $color-white-secondary;
    border-radius: 10px;
    flex-direction: column;
    align-items: center;
    box-shadow: $box-shadow-primary;
    gap: 10px;
    padding: 10px 0;
    transition: opacity 500ms ease-in-out, visibility 500ms;

    .dark-theme & {
      background-color: $color-tertiary;
    }
  }
  .dropdown {
    &__item {
      max-width: 25px;
      width: 100%;
      cursor: pointer;
    }
  }
}
</style>
