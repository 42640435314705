import BaseService from '@/services/base.service';
class ProjectsService extends BaseService {
  constructor() {
    super('projects');
  }

  async getProjects() {
    return this.get();
  }

  async updateProjectLeadership(project, leaderId) {
    const { name, status, start_at, end_at } = project;

    return await this.put({ name, status, start_at, end_at, leader_id: leaderId }, project.id);
  }
}

export default new ProjectsService();
