import { RoutesNames } from '@/constants/routes';

export const systemRoutes = [
  {
    path: '/health-status',
    name: RoutesNames.HealthStatus,
    component: () => import('@/pages/HealthStatus.vue'),
    meta: { isPublic: true },
  },
];
