import BaseService from '@/services/base.service';

class FunctionalitiesService extends BaseService {
  constructor() {
    super('functionalities');
  }

  getFunctionalities() {
    return this.get();
  }
}

export default new FunctionalitiesService();
