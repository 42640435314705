import { SnackbarProgrammatic as Snackbar } from 'buefy';

export const openToast = function (message, type, container) {
  Snackbar.open({
    message,
    position: 'is-bottom-right',
    duration: 5000,
    queue: false,
    actionText: null,
    type,
    container,
    pauseOnHover: false,
  });
};
