import FunctionalitiesService from '@/services/admin-service/functionalities.service';
import PositionsService from '@/services/admin-service/positions.service';
import ProfilesService from '@/services/admin-service/profiles.service';
import BaseService from '@/services/base.service';

class AdminService extends BaseService {
  constructor() {
    super('positions');
  }

  getPositions(page, size, name, profiles) {
    const params = {
      page,
      size,
      name,
      profiles,
    };

    return PositionsService.getPositions({ ...params });
  }

  updatePositions(profiles, positionId) {
    return PositionsService.updatePositions(profiles, positionId);
  }

  getProfiles() {
    return ProfilesService.getProfiles();
  }

  getFunctionalities() {
    return FunctionalitiesService.getFunctionalities();
  }
}

export default new AdminService();
