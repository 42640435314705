import { ErrorCodes } from '@/constants/error-codes';
import i18n from '@/i18n';
import BaseService from '@/services/base.service';
import { openToast } from '@/utils/toast';

class MeService extends BaseService {
  constructor() {
    super('me');
  }

  async getUserWorklog(date) {
    return await this.get(`worklogs/${date}`);
  }

  async getUserProjects() {
    return await this.get(`projects`);
  }

  async getProjectWorklogs(worklogId) {
    return await this.get(`worklogs/${worklogId}/projects-worklog`);
  }

  async addNewProject(projectId) {
    return await this.post({}, `projects/${projectId}`);
  }

  async deleteProject(projectId) {
    return await this.delete(`projects/${projectId}`);
  }

  async updateProjectGlobalPercentage(percentage) {
    return await this.put({ distribution_percentage: percentage }, `projects`);
  }

  async setDistributionPercentages(worklogId, projectWorklogId, projectId, percentage, date) {
    try {
      return await this.put(
        { id: projectId, distribution_percentage: percentage, date},
        `worklogs/${worklogId}/projects-worklog/${projectWorklogId}`,
      );
    } catch (error) {
      const errorCode = error.response.data.error_code;

      if (errorCode === ErrorCodes.projectAlreadyArchived.key) {
        return openToast(ErrorCodes.projectAlreadyArchived.description, 'is-danger');
      }

      openToast(i18n.t('error.common_error'), 'is-danger');
    }
  }
}

export default new MeService();
