import BaseService from '@/services/base.service';

const url = `${process.env.VITE_BASE_URL}/chronos/v2`;

class OvertimeService extends BaseService {
  getTotalOvertime(id) {
    return this.http.get(`hours-balances/?user_id=${id}`, { baseURL: url });
  }

  // eslint-disable-next-line camelcase
  getOvertimeEvents(id, month_year, page, size) {
    return this.http.get(`users/${id}/closing-periods`, {
      params: {
        month_year,
        page,
        size,
      },
      baseURL: url,
    });
  }
}

export default new OvertimeService();
