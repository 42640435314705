import ThemeService from '@/services/theme-service/theme.service';
import { SET_CURRENT_THEME } from '@/store/modules/mutation-types';

export const THEMES = {
  DARK: 'dark-theme',
  LIGHT: 'light-theme',
};

const currentTheme = ThemeService.getTheme();

export default {
  state: {
    theme: currentTheme ? currentTheme : THEMES.LIGHT,
  },

  mutations: {
    [SET_CURRENT_THEME](state, theme) {
      state.theme = theme;
    },
  },

  actions: {
    toggleCurrentTheme: ({ commit, state }) => {
      const newTheme = state.theme === THEMES.DARK ? THEMES.LIGHT : THEMES.DARK;

      ThemeService.setNewTheme(newTheme);
      commit(SET_CURRENT_THEME, newTheme);
    },
  },

  getters: {
    getCurrentTheme(state) {
      return state.theme;
    },
  },
};
