import { SET_PROJECTS, SET_OVERTIME_STATUSES } from './mutation-types';

import ProjectsService from '@/services/projects-service/projects.service';
import { underLoadingFunction } from '@/store/helpers/under-loading';

export default {
  state: {
    projects: [],
    overtimeStatuses: [],
  },

  mutations: {
    [SET_PROJECTS](state, payload) {
      state.projects = payload;
    },

    [SET_OVERTIME_STATUSES](state, status) {
      state.overtimeStatuses.push(status);
    },
  },

  actions: {
    setProjects: underLoadingFunction(async ({ commit, state }) => {
      const response = await ProjectsService.getProjects();
      commit(SET_PROJECTS, [...state.projects, ...response.data]);
    }),

    setOvertimeStatuses: ({ commit }, status) => {
      commit(SET_OVERTIME_STATUSES, status);
    },

    resetOvertimeStatuses: ({ state }) => {
      state.overtimeStatuses = [];
    },

    resetProjects: ({ commit }) => {
      commit(SET_PROJECTS, []);
    },
  },

  getters: {
    getProjects(state) {
      return state.projects;
    },

    getProjectsOvertimeStatuses(state) {
      return state.overtimeStatuses;
    },
  },
};
