import Vue from 'vue';
import Vuex from 'vuex';

import AdminStore from '@/store/modules/admin-store';
import CalendarStore from '@/store/modules/calendar';
import CurrentUserStore from '@/store/modules/current-user';
import OvertimeStore from '@/store/modules/overtime';
import ProjectsStore from '@/store/modules/projects';
import TeamManagementStore from '@/store/modules/team-management';
import ThemeStore from '@/store/modules/theme-store';
import UsersStore from '@/store/modules/users';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoading: undefined,
  },

  getters: {
    isLoading: (state) => {
      return state.isLoading;
    },
  },

  mutations: {
    setLoadingState(state, loading) {
      state.isLoading = loading;
    },
  },

  actions: {
    changeLoadingState({ commit }, loading) {
      commit('setLoadingState', loading);
    },
  },
  modules: {
    AdminStore,
    OvertimeStore,
    ProjectsStore,
    UsersStore,
    CalendarStore,
    TeamManagementStore,
    CurrentUserStore,
    ThemeStore,
  },
});
