import * as MutationTypes from './mutation-types';

export default {
  state: {
    todayDate: '',
    week: '',
  },

  mutations: {
    [MutationTypes.SET_TODAY](state, payload) {
      state.todayDate = payload;
    },
  },

  actions: {
    setTodayDate({ commit }, payload) {
      commit('SET_TODAY', payload);
    },
  },

  getters: {
    getToday(state) {
      return state.todayDate;
    },
  },
};
