import Vue from 'vue';

import {
  SET_TIME_REQUESTS,
  SET_DAYS_OFF,
  UPDATE_TIME_REQUEST,
  SET_TIME_REQUESTS_LOADING,
  SET_DAYS_OFF_LOADING,
} from './mutation-types';

import DaysOffService from '@/services/days-off-service/days-off.service';
import ExceedingHoursService from '@/services/exceeding-hours-service/exceeding-hours.service';
import { underLoadingFunction } from '@/store/helpers/under-loading';

export default {
  state: {
    timeRequests: [],
    daysOff: [],
    timeRequest: {},
    timeRequestLoading: false,
    daysOffLoading: false,
  },

  mutations: {
    [SET_TIME_REQUESTS](state, payload) {
      state.timeRequests = payload;
    },

    [SET_DAYS_OFF](state, payload) {
      state.daysOff = payload;
    },

    [UPDATE_TIME_REQUEST](state, timeRequest) {
      let index = state.timeRequests.findIndex((p) => p.id === timeRequest.id);
      Vue.set(state.timeRequests, index, timeRequest);
    },

    [SET_TIME_REQUESTS_LOADING](state, payload) {
      state.timeRequestLoading = payload;
    },

    [SET_DAYS_OFF_LOADING](state, payload) {
      state.daysOffLoading = payload;
    },
  },

  actions: {
    setTimeRequests: underLoadingFunction(async ({ commit, state }, { month_year, leader_id, user_id }) => {
      try {
        commit(SET_TIME_REQUESTS_LOADING, true);

        const response = await ExceedingHoursService.fetchUsersExceedingHoursRequests(month_year, leader_id, user_id);
        commit(SET_TIME_REQUESTS, [...state.timeRequests, ...response.data]);
      } catch (error) {
        commit(SET_TIME_REQUESTS, []);
      } finally {
        commit(SET_TIME_REQUESTS_LOADING, false);
      }
    }),

    setDaysOff: underLoadingFunction(async ({ commit, state }, { month_year, leader_id, user_id }) => {
      try {
        commit(SET_DAYS_OFF_LOADING, true);

        const response = await DaysOffService.fetchAllDayOffs(month_year, leader_id, user_id);
        commit(SET_DAYS_OFF, [...state.daysOff, ...response.data]);
      } catch (error) {
        commit(SET_DAYS_OFF, []);
      } finally {
        commit(SET_DAYS_OFF_LOADING, false);
      }
    }),

    updateTimeRequest: underLoadingFunction(async ({ commit }, { id, status }) => {
      const response = await ExceedingHoursService.updateTimeRequest(id, status);
      commit(UPDATE_TIME_REQUEST, response.data);
    }),

    resetTimeRequests: ({ commit }) => {
      commit(SET_TIME_REQUESTS, []);
    },

    resetDaysOff: ({ commit }) => {
      commit(SET_DAYS_OFF, []);
    },
  },

  getters: {
    getTimeRequests(state) {
      return state.timeRequests;
    },

    getDaysOff(state) {
      return state.daysOff;
    },

    getTimeRequestsLoading(state) {
      return state.timeRequestLoading;
    },

    getDaysOffLoading(state) {
      return state.daysOffLoading;
    },
  },
};
