import BaseService from '@/services/base.service';

class ProfilesService extends BaseService {
  constructor() {
    super('profiles');
  }

  getProfiles() {
    return this.get();
  }
}

export default new ProfilesService();
