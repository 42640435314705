import { RoutesNames } from '@/constants/routes';

export const teamManagementRoutes = [
  {
    path: '/team-management',
    name: RoutesNames.TeamManagement,
    component: () => import('@/pages/teamManagement/Index.vue'),
    meta: { authorize: ['ROLE_TEAM_MANAGEMENT'] },
  },
];
