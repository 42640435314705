import { RoutesNames } from '@/constants/routes';

export const homeRoutes = [
  {
    path: '/',
    name: RoutesNames.Home,
    component: () => import('@/pages/home/Index.vue'),
    meta: { authorize: ['ROLE_TIME_RECORDING'] },
  },
];
