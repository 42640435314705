export const Authorities = [
  {
    key: 'ROLE_TIME_RECORDING',
  },
  {
    key: 'ROLE_TIME_RECORDING_HISTORY',
  },
  {
    key: 'ROLE_MANAGEMENT_REPORTS',
  },
  {
    key: 'ROLE_ACCESS_MANAGEMENT',
  },
  {
    key: 'ROLE_WORK_PERIOD_UNBLOCK',
  },
  {
    key: 'ROLE_WORK_HOURS_BANK',
  },
  {
    key: 'ROLE_TEAM_MANAGEMENT',
  },
  {
    key: 'ROLE_USER_DAY_OFFS',
  },
  {
    key: 'ROLE_RETRIEVE_USERS',
  },
  {
    key: 'ROLE_EXCEEDING_HOURS_MANAGEMENT',
  },
];

export const AUTHORITIES = {
  ROLE_USER: 'ROLE_USER',
  ROLE_ADMINISTRATION: 'ROLE_ADMINISTRATION',
  ROLE_TIME_RECORDING: 'ROLE_TIME_RECORDING',
  ROLE_TIME_RECORDING_HISTORY: 'ROLE_TIME_RECORDING_HISTORY',
  ROLE_MANAGEMENT_REPORTS: 'ROLE_MANAGEMENT_REPORTS',
  ROLE_ACCESS_MANAGEMENT: 'ROLE_ACCESS_MANAGEMENT',
  ROLE_WORK_PERIOD_UNBLOCK: 'ROLE_WORK_PERIOD_UNBLOCK',
  ROLE_WORK_HOURS_BANK: 'ROLE_WORK_HOURS_BANK',
  ROLE_TEAM_MANAGEMENT: 'ROLE_TEAM_MANAGEMENT',
  ROLE_USER_DAY_OFFS: 'ROLE_USER_DAY_OFFS',
  ROLE_RETRIEVE_USERS: 'ROLE_RETRIEVE_USERS',
  ROLE_EXCEEDING_HOURS_MANAGEMENT: 'ROLE_EXCEEDING_HOURS_MANAGEMENT',
  ROLE_DETAILED_DATES: 'ROLE_DETAILED_DATES',
  ROLE_USER_HOLIDAYS: 'ROLE_USER_HOLIDAYS',
};
