import { RoutesNames } from '@/constants/routes';

export const unlockWorkPeriodRoutes = [
  {
    path: '/work-period-unblock',
    name: RoutesNames.UnlockWorkPeriod,
    component: () => import('@/pages/unlockWorkPeriod/IndexV2.vue'),
    meta: { authorize: ['ROLE_WORK_PERIOD_UNBLOCK'] },
  },
];
