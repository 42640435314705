import qs from 'qs';

import BaseService from '@/services/base.service';

class DaysOffService extends BaseService {
  async fetchAllDayOffs(month_year, leader_id, user_id) {
    return this.http.get('/day-offs', {
      params: {
        month_year,
        leader_id,
        ...(user_id ? { user_id } : []),
      },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  }
}

export default new DaysOffService();
