import axios from 'axios';

import AuthService from '@/services/auth-service/auth.service';

export const REQUEST_DEFAULT_TIMEOUT = 40000;
const TIMEOUT_ERROR = 'ECONNABORTED';

const instance = axios.create({
  baseURL: `${process.env.VITE_BASE_URL}/chronos/v1`,
  timeout: REQUEST_DEFAULT_TIMEOUT,
});

function redirectToRoute(routeName) {
  const redirectProtocol = window.location.protocol;
  const redirectHostname = window.location.hostname;
  const redirectPort = window.location.port;
  const redirectRoute = routeName;
  window.location.href = `${redirectProtocol}//${redirectHostname}:${redirectPort}${redirectRoute}`;
}

const checkServerStatus = async () => {
  try {
    const token = AuthService.getToken();
    const url = `${process.env.VITE_BASE_URL}/chronos/actuator/health`;
    await axios({
      url,
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    redirectToRoute('/health-status');
  }
};

instance.interceptors.request.use(
  (config) => {
    const token = AuthService.getToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response, code } = error;

    const timedOut = code === TIMEOUT_ERROR;
    const serverDown = response.status === 500;
    const unauthorized = response.status === 401;

    if (serverDown || timedOut || !error.response.status) {
      checkServerStatus();
    }

    if (unauthorized) {
      redirectToRoute('/login');
    }

    return Promise.reject(error);
  },
);

export default instance;
