import Vue from 'vue';

import { FALLBACK_TIMEZONE } from '@/constants/user';
import MeService from '@/services/me-service/me.service';
import ProjectWorklogsService from '@/services/project-worklogs-service/project-worklogs.service';
import WorklogsService from '@/services/worklogs-service/worklogs.service';
import { underLoadingFunction } from '@/store/helpers/under-loading';
import {
  SET_CURRENT_USER,
  SET_USER_WORKLOG,
  SET_USER_PROJECTS_WORKLOGS,
  SET_WORKLOG_PERIODS,
  SET_WORKLOG_PERIODS_LOADING,
  DELETE_USER_WORKLOG,
  DELETE_USER_PROJECT,
  UPDATE_USER_PROJECT,
  UPDATE_WORKLOG_PROJECT,
  SET_USER_PROJECTS,
} from '@/store/modules/mutation-types';

const UserState = {
  user: {},
  userProjects: [],
  worklog: {
    date: '',
    id: '',
    periods: [],
    projectsWorklog: [],
  },
  worklogPeriods: [],
  worklogPeriodsLoading: false,
  projectWorklogs: [],
};

export default {
  state: { ...UserState, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone || FALLBACK_TIMEZONE },

  mutations: {
    [SET_CURRENT_USER](state, user) {
      state.user = user;
    },

    [SET_USER_WORKLOG](state, worklog) {
      state.worklog = worklog;
    },

    [DELETE_USER_WORKLOG](state) {
      state.worklog = UserState.worklog;
      state.worklogPeriods = UserState.worklogPeriods;
    },

    [SET_WORKLOG_PERIODS](state, periods) {
      state.worklogPeriods = periods;
    },

    [SET_WORKLOG_PERIODS_LOADING](state, payload) {
      state.worklogPeriodsLoading = payload;
    },

    [SET_USER_PROJECTS_WORKLOGS](state, projectWorklogs) {
      state.projectWorklogs = projectWorklogs;
    },

    [UPDATE_WORKLOG_PROJECT](state, worklogProject) {
      const index = state.projectWorklogs.findIndex((userProject) => userProject.id === worklogProject.id);
      Vue.set(state.projectWorklogs, index, worklogProject);
    },

    [SET_USER_PROJECTS](state, projects) {
      state.userProjects = projects;
    },

    [UPDATE_USER_PROJECT](state, project) {
      const index = state.userProjects.findIndex((userProject) => userProject.id === project.id);
      Vue.set(state.userProjects, index, project);
    },

    [DELETE_USER_PROJECT](state, projectId) {
      state.userProjects = state.userProjects.filter((p) => p.id !== projectId);
    },
  },

  actions: {
    setCurrentUser: async ({ commit }) => {
      const response = await MeService.get();
      commit(SET_CURRENT_USER, response.data);
    },

    setUserWorklog: async ({ commit }, date) => {
      try {
        commit(SET_WORKLOG_PERIODS_LOADING, true);

        const response = await MeService.getUserWorklog(date);

        commit(SET_USER_WORKLOG, response.data);
        commit(SET_WORKLOG_PERIODS, response.data.periods);
      } catch {
        commit(SET_USER_WORKLOG, UserState.worklog);
        commit(SET_WORKLOG_PERIODS, UserState.worklogPeriods);
      } finally {
        commit(SET_WORKLOG_PERIODS_LOADING, false);
      }
    },

    deleteUserWorklogs: underLoadingFunction(async ({ commit }, { userId, date }) => {
      await WorklogsService.deleteDayWorklog(userId, date);
      commit(DELETE_USER_WORKLOG);
    }),

    updateWorklogProject: async ({ commit }, { worklogId, projectWorklogId, projectId, percentage, date }) => {
      const response = await MeService.setDistributionPercentages(worklogId, projectWorklogId, projectId, percentage, date);
      commit(UPDATE_WORKLOG_PROJECT, response.data);
    },

    updateProjectWorklog: async (
      { commit },
      {
        projectWorklogId,
        project_id,
        worklog_id,
        worked_interval_minutes,
        exceeding_worked_minutes,
        distribution_percentage,
      },
    ) => {
      const params = {
        project_id,
        worklog_id,
        worked_interval_minutes,
        exceeding_worked_minutes,
        distribution_percentage,
      };

      const { data } = await ProjectWorklogsService.updateProjectWorklog(projectWorklogId, params);
      commit(UPDATE_WORKLOG_PROJECT, data);
    },

    setUserProjectsWorklogs: async ({ commit }, worklogId) => {
      const response = await MeService.getProjectWorklogs(worklogId);
      commit(SET_USER_PROJECTS_WORKLOGS, response.data);
    },

    setUserProjects: async ({ commit }) => {
      const { data } = await MeService.getUserProjects();
      commit(SET_USER_PROJECTS, data);
    },

    addNewProject: underLoadingFunction(async ({ commit }, { projectId }) => {
      const { data } = await MeService.addNewProject(projectId);
      commit(SET_USER_PROJECTS, data);
    }),

    updateProjectGlobalPercentage: async ({ commit }, { percentage }) => {
      const response = await MeService.updateProjectGlobalPercentage(percentage);
      const { data } = response;
      commit(UPDATE_USER_PROJECT, data);
    },

    deleteUserProject: underLoadingFunction(async ({ commit }, { projectId }) => {
      await MeService.deleteProject(projectId);
      commit(DELETE_USER_PROJECT, projectId);
    }),

    resetUserInfo: ({ commit }) => {
      commit(SET_CURRENT_USER, UserState.user);
    },
  },

  getters: {
    getCurrentUser(state) {
      return state.user;
    },
    getWorklog(state) {
      return state.worklog;
    },
    getWorklogPeriods(state) {
      return state.worklogPeriods;
    },
    getUserTimezone(state) {
      return state.timeZone;
    },
    getUserProjects(state) {
      return state.userProjects;
    },
    isWorklogPeriodsLoading(state) {
      return state.worklogPeriodsLoading;
    },
  },
};
