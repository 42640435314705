export default {
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    window.addEventListener('resize', this.getWindowSize);
    this.getWindowSize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowSize);
  },
  computed: {
    isPhoneOnly() {
      return this.windowWidth <= 500;
    },
    isTabletPortrait() {
      return this.windowWidth > 500 && this.windowWidth <= 768;
    },
    isTabletLandscape() {
      return this.windowWidth > 768 && this.windowWidth <= 992;
    },
    isDesktop() {
      return this.windowWidth > 992;
    },
  },
  methods: {
    getWindowSize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
