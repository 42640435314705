import Vue from 'vue';
import VueGtag from 'vue-gtag';
import Router from 'vue-router';

import { RoutesNames } from '@/constants/routes';
import { accessManagementRoutes } from '@/router/routes/accessManagement/Index';
import { appointedLeadershipRoutes } from '@/router/routes/appointedLeadership/Index';
import { homeRoutes } from '@/router/routes/home/Index';
import { loginRoutes } from '@/router/routes/login/Index';
import { managementReportsRoutes } from '@/router/routes/managementReports/Index';
import { systemRoutes } from '@/router/routes/system/Index';
import { teamManagementRoutes } from '@/router/routes/teamManagement/Index';
import { unlockWorkPeriodRoutes } from '@/router/routes/unlockWorkPeriod/Index';
import { workHoursBankRoutes } from '@/router/routes/workHoursBank/Index';
import AuthService from '@/services/auth-service/auth.service';
import GlobalStore from '@/store';
import CurrentUserStore from '@/store/modules/current-user';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    ...accessManagementRoutes,
    ...appointedLeadershipRoutes,
    ...homeRoutes,
    ...loginRoutes,
    ...managementReportsRoutes,
    ...systemRoutes,
    ...teamManagementRoutes,
    ...unlockWorkPeriodRoutes,
    ...workHoursBankRoutes,
  ],
});

router.beforeEach(async (to, from, next) => {
  const { isPublic } = to.meta;

  if (!isPublic && Object.keys(CurrentUserStore.state.user).length === 0) {
    try {
      await GlobalStore.dispatch('setCurrentUser');
    } catch (error) {
      GlobalStore.dispatch('resetUserInfo');
      await AuthService.logout();

      return next({
        name: RoutesNames.Login,
      });
    }
  }

  next();
});

Vue.use(
  VueGtag,
  {
    config: {
      id: process.env.VITE_CHRONOS_GA_ID,
    },
    params: {
      user_id: CurrentUserStore.state.user.id || null,
    },
    pageTrackerTemplate(to) {
      return {
        page_title: to.name,
        page_path: to.path,
      };
    },
  },
  router,
);

export default router;
