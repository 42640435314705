import { RoutesNames } from '@/constants/routes';

export const workHoursBankRoutes = [
  {
    path: '/work-hours-bank',
    name: RoutesNames.WorkHoursBank,
    component: () => import('@/pages/workHoursBank/Index.vue'),
    meta: { authorize: ['ROLE_WORK_HOURS_BANK'] },
  },
];
