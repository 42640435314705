export const SET_TOTAL_OVERTIME = 'SET_TOTAL_OVERTIME';
export const SET_OVERTIME_EVENTS = 'SET_OVERTIME_EVENTS';
export const SET_DATE_FILTER = 'SET_DATE_FILTER';
export const SET_OVERTIME_CLOSING_EVENTS = 'SET_OVERTIME_CLOSING_EVENTS';
export const SET_OVERTIME_EVENTS_PAGE = 'SET_OVERTIME_EVENTS_PAGE';

export const SET_PROFILES = 'SET_PROFILES';
export const SET_POSITIONS = 'SET_POSITIONS';
export const SET_POSITIONS_LOADING = 'SET_POSITIONS_LOADING';
export const SET_FUNCTIONALITIES = 'SET_FUNCTIONALITIES';
export const ACCESSES_TOTAL_PAGES = 'ACCESSES_TOTAL_PAGES';
export const SET_POSITIONS_TOTAL_REGISTRIES = 'SET_POSITIONS_TOTAL_REGISTRIES';
export const UPDATE_POSITION = 'UPDATE_POSITION';

export const SET_USER_PROJECTS = 'SET_USER_PROJECTS';
export const SET_PROJECTS = 'SET_PROJECTS';
export const DELETE_USER_PROJECT = 'DELETE_USER_PROJECT';
export const SET_USERS = 'SET_USERS';
export const SET_USER_DETAILED_DATES = 'SET_USER_DETAILED_DATES';
export const UPDATE_USER_PROJECT = 'UPDATE_USER_PROJECT';
export const SET_OVERTIME_STATUSES = 'SET_OVERTIME_STATUSES';
export const SET_USER_ALLOCATION = 'SET_USER_ALLOCATION';
export const UPDATE_USER_ALLOCATION = 'UPDATE_USER_ALLOCATION';

export const SET_USER_WORKLOG = 'SET_USER_WORKLOG';
export const DELETE_USER_WORKLOG = 'DELETE_USER_WORKLOG';

export const SET_TODAY = 'SET_TODAY';

export const SET_TIME_REQUESTS = 'SET_TIME_REQUESTS';
export const SET_TIME_REQUESTS_LOADING = 'SET_TIME_REQUESTS_LOADING';
export const SET_TEAMMATES = 'SET_TEAMMATES';
export const SET_TEAMMATES_LOADING = 'SET_TEAMMATES_LOADING';
export const SET_DAYS_OFF = 'SET_DAYS_OFF';
export const SET_DAYS_OFF_LOADING = 'SET_DAYS_OFF_LOADING';
export const UPDATE_TIME_REQUEST = 'UPDATE_TIME_REQUEST';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_USER_PROJECTS_WORKLOGS = 'SET_USER_PROJECTS_WORKLOGS';
export const SET_WORKLOG_PERIODS = 'SET_WORKLOG_PERIODS';
export const SET_WORKLOG_PERIODS_LOADING = 'SET_WORKLOG_PERIODS_LOADING';
export const UPDATE_WORKLOG_PROJECT = 'UPDATE_WORKLOG_PROJECT';
export const SET_CALENDAR_EVENTS = 'SET_CALENDAR_EVENTS';

export const SET_CURRENT_THEME = 'SET_CURRENT_THEME';
