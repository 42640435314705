import BaseService from '@/services/base.service';

class WorklogsService extends BaseService {
  constructor() {
    super('worklogs');
  }

  deleteDayWorklog(userId, date) {
    return this.delete(`${userId}?date=${date}`);
  }
}

export default new WorklogsService();
