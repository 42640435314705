import _ from 'lodash';

export default {
  methods: {
    sortItemsByName(item) {
      const sortedArray = _.sortBy(item, (i) => {
        return _.deburr(i.name);
      });
      return sortedArray;
    },
  },
};
