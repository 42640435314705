import Vue from 'vue';

import { DETAILED_DATES_EMPTY_STATE } from '@/constants/detailed-dates';
import UserService from '@/services/user-service/user.service';
import { underLoadingFunction } from '@/store/helpers/under-loading';
import {
  SET_USERS,
  SET_USER_DETAILED_DATES,
  SET_TEAMMATES,
  SET_TEAMMATES_LOADING,
  SET_CALENDAR_EVENTS,
  SET_USER_ALLOCATION,
  UPDATE_USER_ALLOCATION,
} from '@/store/modules/mutation-types';

const UsersState = {
  users: [],
  teammates: [],
  teammatesLoading: false,
  detailedDates: DETAILED_DATES_EMPTY_STATE.detailedMonth,
  calendarEvents: [],
  userAllocation: [],
};

export default {
  state: {
    ...UsersState,
  },

  mutations: {
    [SET_USERS](state, payload) {
      state.users = payload;
    },

    [SET_USER_DETAILED_DATES](state, detailedDates) {
      state.detailedDates = detailedDates;
    },

    [SET_TEAMMATES](state, payload) {
      state.teammates = payload;
    },

    [SET_TEAMMATES_LOADING](state, payload) {
      state.teammatesLoading = payload;
    },

    [SET_CALENDAR_EVENTS](state, payload) {
      state.calendarEvents = payload;
    },

    [SET_USER_ALLOCATION](state, payload) {
      state.userAllocation = payload;
    },

    [UPDATE_USER_ALLOCATION](state, payload) {
      const index = state.userAllocation.findIndex((userAllocation) => userAllocation.id === payload.id);
      Vue.set(state.userAllocation, index, payload);
    },
  },

  actions: {
    fetchAllUsers: underLoadingFunction(async ({ commit, state }, payload) => {
      const response = await UserService.fetchUsers({ ...payload });
      commit(SET_USERS, [...state.users, ...response.data]);
    }),

    setTeammates: underLoadingFunction(async ({ commit, state }, { leader_id, month_year }) => {
      try {
        commit(SET_TEAMMATES_LOADING, true);

        const response = await UserService.fetchUsersFromTheSameLeader(leader_id, month_year);
        commit(SET_TEAMMATES, [...state.teammates, ...response.data]);
      } catch (error) {
        commit(SET_TEAMMATES, []);
      } finally {
        commit(SET_TEAMMATES_LOADING, false);
      }
    }),

    setUserAllocation: async ({ commit }, { userId, date }) => {
      const response = await UserService.getUserAllocation(userId, date);
      commit(SET_USER_ALLOCATION, response.data);
    },

    updateProjectPercentage: async ({ commit }, { userId, allocation, percentage }) => {
      const response = await UserService.updateProjectPercentage(userId, allocation, percentage);
      commit(UPDATE_USER_ALLOCATION, response.data);
    },

    setUserDetailedDates: async ({ commit }, { userId, monthYear }) => {
      const response = await UserService.detailedDates(userId, monthYear);
      commit(SET_USER_DETAILED_DATES, response.data);
      commit(SET_CALENDAR_EVENTS, response.data.detailed_dates);
    },

    setCalendarEvents: async ({ commit }, { userId, monthYear }) => {
      const { data } = await UserService.detailedDates(userId, monthYear);
      commit(SET_CALENDAR_EVENTS, data.detailed_dates);
    },

    resetUsers: ({ commit }) => {
      commit(SET_USERS, []);
    },

    resetDetailedDates: ({ commit }) => {
      commit(SET_USER_DETAILED_DATES, UsersState.detailedDates);
    },

    resetTeammates: ({ commit }) => {
      commit(SET_TEAMMATES, []);
    },
  },

  getters: {
    getUsers(state) {
      return state.users;
    },

    getUserAllocation(state) {
      return state.userAllocation;
    },

    getUserDetailedDates(state) {
      return state.detailedDates;
    },

    getTeammates(state) {
      return state.teammates;
    },

    getTeammatesLoading(state) {
      return state.teammatesLoading;
    },

    getCalendarEvents(state) {
      return state.calendarEvents;
    },
  },
};
