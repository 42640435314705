export default {
  methods: {
    formattedName(name) {
      const splittedName = name.split(' ');
      const newName = `${splittedName[0]} ${splittedName[splittedName.length - 1]}`;
      const nameToLowerCase = newName.toLowerCase();

      return nameToLowerCase;
    },
  },
};
