export const RoutesNames = {
  Login: 'login',
  Home: 'home',
  ManagementReports: 'managementReports',
  WorkHoursBank: 'workHoursBank',
  AccessManagement: 'accessManagement',
  AppointedLeadership: 'appointedLeadership',
  TeamManagement: 'teamManagement',
  UnlockWorkPeriod: 'unlockWorkPeriod',
  HealthStatus: 'healthStatus',
};

export const PublicRoutes = [RoutesNames.HealthStatus, RoutesNames.Login];
