export default {
  methods: {
    gtm(payload) {
      this.$gtag.event(payload.label, {
        event_category: payload.category,
        action: payload.action,
        event_label: payload.label,
        ...(payload.custom_map || null),
        value: payload.value || null,
      });
    },
  },
};
