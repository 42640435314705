<template>
  <header class="header">
    <button class="header__logo-btn" @click="handleRedirectToHome">
      <img src="@/assets/image/logo.svg" :alt="$t('shared.image_alt.logo')" class="header__logo" />
    </button>
    <div class="header__menu">
      <ul class="header__menu-routes">
        <router-link
          v-for="route in menuIlianRoutes"
          v-show="hasPermissionToAccess(route.permission)"
          :key="route.id"
          :to="{ name: route.route }"
          exact
          class="header__menu-routes-route"
        >
          <em :class="route.icon" class="header__menu-routes-icon" />
          {{ route.name }}
        </router-link>
        <ul v-show="userHasPermission" class="dropdown" @click="toggleMenu()">
          <div class="dropdown__header">
            <em class="icon-ic_menu dropdown__header__icon" />
            <h4 class="dropdown__title">{{ $t('home.menu') }}</h4>
          </div>
          <li :class="{ show: showDropdown }" class="dropdown__content">
            <router-link
              v-for="route in sortItemsByName(menuLeaderRoutes)"
              v-show="hasPermissionToAccess(route.permission)"
              :key="route.id"
              :to="{ name: route.route }"
              exact
              class="dropdown__content__links"
            >
              <em :class="route.icon" class="dropdown__content__links__icons" />
              <h4 class="dropdown__content__links__txt">{{ route.name }}</h4>
            </router-link>
          </li>
        </ul>
      </ul>
      <ul class="header__menu-user">
        <ThemeChanger />
        <li class="header__menu-user-name">
          {{ formattedName(user?.name || '') }}
        </li>
        <img
          :src="user.avatar"
          :alt="$t('shared.image_alt.avatar')"
          referrerpolicy="no-referrer"
          class="header__menu-user-image"
        />
        <SwitchLanguage />
        <button class="header__menu-user-button" @click="signOut">
          <em class="header__menu-user-icon icon-ic_logout" />
        </button>
      </ul>
    </div>
    <div class="header__menu-mobile">
      <BDropdown :mobile-modal="false" expanded aria-role="menu" position="is-bottom-left">
        <template #trigger>
          <img
            :src="user.avatar"
            :alt="$t('shared.image_alt.avatar')"
            referrerpolicy="no-referrer"
            class="header__image"
          />
        </template>
        <BDropdownItem v-for="route in menuIlianRoutes" :key="route.id" has-link>
          <router-link v-show="hasPermissionToAccess(route.permission)" :to="{ name: route.route }" exact>
            {{ route.name }}
          </router-link>
        </BDropdownItem>
        <BDropdownItem
          v-for="route in sortItemsByName(menuLeaderRoutes)"
          v-show="userHasPermission"
          :key="route.id"
          has-link
        >
          <router-link v-show="hasPermissionToAccess(route.permission)" :to="{ name: route.route }" exact>
            {{ route.name }}
          </router-link>
        </BDropdownItem>
      </BDropdown>
      <ThemeChanger />
      <SwitchLanguage />
      <button class="header__menu-user-button" @click="signOut">
        <em class="header__menu-user-icon icon-ic_logout" />
      </button>
    </div>
  </header>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import SwitchLanguage from '@/components/shared/switchLanguage/SwitchLanguage.vue';
import ThemeChanger from '@/components/shared/themeChanger/ThemeChanger.vue';
import { Authorities } from '@/constants/permissions/authorities';
import { RoutesNames } from '@/constants/routes';
import formattedName from '@/mixins/formattedName';
import menuRoutes from '@/mixins/menuItems';
import sortItemsByName from '@/mixins/sortItemsByName';
import AuthService from '@/services/auth-service/auth.service';

export default {
  name: 'Header',
  components: {
    SwitchLanguage,
    ThemeChanger,
  },
  mixins: [sortItemsByName, menuRoutes, formattedName],

  data() {
    return {
      showDropdown: false,
    };
  },

  computed: {
    ...mapGetters({ user: 'getCurrentUser' }),
    userHasPermission() {
      return this.user
        ? this.userPermissions.includes('ROLE_MANAGEMENT_REPORTS') ||
            this.userPermissions.includes('ROLE_ACCESS_MANAGEMENT') ||
            this.userPermissions.includes('ROLE_WORK_PERIOD_UNBLOCK') ||
            this.userPermissions.includes('ROLE_TEAM_MANAGEMENT')
        : false;
    },

    userPermissions() {
      return this.user ? _.intersection(this.allPermissions, this.user.authorities) : null;
    },

    allPermissions() {
      return Authorities.map((a) => a.key);
    },
  },

  methods: {
    ...mapActions(['resetUserInfo', 'toggleCurrentTheme']),
    signOut() {
      this.resetUserInfo();

      AuthService.logout();

      this.gtm({ category: 'logout', label: 'log-out', action: 'click', value: null });
      this.$router.push({ name: RoutesNames.Login });
    },

    handleRedirectToHome() {
      this.$router.push({ name: RoutesNames.Home });
    },

    hasPermissionToAccess(permission) {
      if (this.userPermissions) {
        const hasPermission = this.userPermissions.includes(permission);
        return hasPermission;
      }
    },

    toggleMenu() {
      this.showDropdown = !this.showDropdown;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  height: 70px;
  width: 100%;
  padding: 0 24px;
  top: 0;
  left: 0;
  z-index: 11;
  background-color: $color-white-secondary;
  box-shadow: $box-shadow-primary;
  .dark-theme & {
    background-color: $color-primary;
    border-bottom: 1px solid $border-primary;
  }

  &__logo {
    flex: 1;
    max-width: 180px;
    width: 100%;

    @include for-phone-only() {
      max-width: 120px;
    }
  }

  &__logo-btn {
    cursor: pointer;
  }

  &__menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    gap: 42px;
    flex: 1;
    @include for-tablet-portrait-down() {
      display: none;
    }

    &-routes,
    &-user {
      display: flex;
      align-items: center;
      height: 100%;
      gap: 24px;
    }

    &-routes {
      &-route {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 6px;
        height: 100%;
        min-width: 90px;
        font-size: $font-size-xxxs;
        color: $color-blue-light;
        @include color-transition();

        .dark-theme & {
          color: $text-primary;
        }

        &:hover,
        &.router-link-active {
          color: $color-purple;
        }
      }

      &-icon {
        font-size: 24px;
      }
    }

    &-user {
      &-name {
        font-size: $font-size-s;
        font-weight: $fdemibold;
        color: $color-blue-light;
        text-transform: capitalize;
        white-space: nowrap;

        .dark-theme & {
          color: $text-primary;
        }

        @include for-tablet-landscape-down() {
          display: none;
        }
      }

      &-image {
        min-width: 45px;
        max-width: 45px;
        height: 45px;
        border: 1.5px solid $color-blue-light;
        border-radius: 50%;

        .dark-theme & {
          border-color: $border-primary;
        }
      }

      &-button {
        background: none;
        border: none;
        cursor: pointer;
        color: $color-blue-light;
        @include color-transition();

        .dark-theme & {
          color: $text-primary;
        }

        &:hover {
          color: $color-purple;
        }
      }

      &-icon {
        font-size: 24px;
      }
    }

    .dropdown {
      position: relative;
      max-width: 120px;
      height: 100%;
      width: 100%;
      cursor: pointer;

      &__header {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 8px 0 0 0;
        height: 100%;
        gap: 5px;
        &:hover,
        &:focus {
          .dropdown__title,
          .dropdown__header__icon {
            color: $color-purple;
          }
        }

        &__icon {
          font-size: 24px;
          color: $color-blue-light;
          @include color-transition();

          .dark-theme & {
            color: $text-primary;
          }
        }
      }

      &__title {
        @include base-title-font($color-blue-light, $f-size: 12px, $f-weight: $fmedium, $l-height: 14px);
        height: 26px;
        max-width: 93px;
        display: flex;
        align-items: center;
        @include color-transition();

        .dark-theme & {
          color: $text-primary;
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        visibility: hidden;
        opacity: 0;
        height: auto;
        width: 240px;
        max-width: 240px;
        padding: 10px 0;
        align-items: center;
        position: absolute;
        gap: 8px;
        top: 100%;
        left: 0;
        z-index: 1;
        background-color: $color-oleander;
        border-radius: 10px;
        transition: opacity 200ms ease-out;
        @extend %primary-shadow;

        .dark-theme & {
          background-color: $color-tertiary;
        }

        &.show {
          visibility: visible;
          opacity: 1;
          transition: opacity 200ms ease-in;
        }

        &__links {
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: 20px;
          min-width: 100%;
          height: 38px;
          padding: 0 18px;
          margin: 0;

          &:hover,
          &:focus,
          &:active,
          &.router-link-exact-active {
            background-color: $color-magenta;
            background-image: none;

            .dropdown__content__links__icons,
            .dropdown__content__links__txt {
              color: $color-purple;
            }
          }

          &:hover,
          &.router-link-active {
            color: $color-purple;
            background-color: $color-magenta;

            .dark-theme & {
              background-color: initial;
            }
          }

          &__icons {
            display: flex;
            justify-content: center;
            flex-shrink: 0;
            font-size: 24px;
            width: 28px;
            color: $color-grey-dark;
            @include color-transition();

            .dark-theme & {
              color: $text-primary;
            }
          }

          &__txt {
            @include base-title-font($color-gray-dark, $f-size: 14px, $f-weight: normal, $l-height: 16px);
            display: flex;
            justify-content: center;
            text-align: center;
            min-width: 150px;
            width: 100%;
            @include color-transition();

            .dark-theme & {
              color: $text-primary;
            }
          }
        }
      }
    }
  }

  &__menu-mobile {
    display: none;
    @include for-tablet-portrait-down() {
      display: flex;
      align-items: center;
      height: 100%;
      gap: 16px;
    }

    &::v-deep .dropdown-content {
      .dark-theme & {
        background-color: $background-primary !important;
        border: 1px solid $border-primary;
      }
    }

    ::v-deep.has-link {
      a {
        font-size: $font-size-xxs !important;
        color: $color-blue-light;
        .dark-theme & {
          color: $text-primary;
        }
        &.router-link-active {
          color: $color-purple;
          font-weight: $fdemibold;
          background-color: $color-magenta;

          .dark-theme & {
            background-color: $hover-primary;
            border-top: 1px solid $color-purple;
            border-bottom: 1px solid $color-purple;
          }
        }
      }

      &:hover {
        a {
          color: $color-purple;
        }
      }
    }
  }

  &__image {
    min-width: 45px;
    max-width: 45px;
    height: 45px;
    border: 1.5px solid $color-blue-light;
    border-radius: 50%;
  }
}
</style>
