import moment from 'moment-timezone';
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);
Object.defineProperty(Vue.prototype, '$moment', { value: moment });

function loadLocaleMessages() {
  const messages = {};
  const locales = import.meta.globEager('./locales/*.json');

  for (const path in locales) {
    if (locales.hasOwnProperty(path)) {
      const matched = path.match(/([A-Za-z0-9-_]+)\.json$/i);
      if (matched && matched.length > 1) {
        const locale = matched[1];
        messages[locale] = locales[path];
      }
    }
  }

  return messages;
}

const availableLocales = ['pt', 'en'];
const browserLocale = navigator.language.slice(0, 2);

const defaultLocale = availableLocales.includes(browserLocale) ? browserLocale : 'en';

moment.updateLocale('pt', {
  weekdays: 'Domingo_Segunda_Terça_Quarta_Quinta_Sexta_Sábado'.split('_'),
  months: 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_'),
});

const dateTimeFormats = {
  en: {
    long: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
  },
  pt: {
    long: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
  },
};

const i18n = new VueI18n({
  locale: defaultLocale,
  messages: loadLocaleMessages(),
  dateTimeFormats,
});

export default i18n;
