<template>
  <div id="app" :class="currentTheme">
    <Header v-if="shouldShowHeader" />
    <RouteTransition />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Header from '@/components/header/Header.vue';
import RouteTransition from '@/components/RouteTransition.vue';
import { injectGtmScript, isGtmScriptOnBody } from '@/plugins/gtmScript';
import ThemeService from '@/services/theme-service/theme.service';
export default {
  components: {
    RouteTransition,
    Header,
  },
  computed: {
    ...mapGetters({ currentUser: 'getCurrentUser', currentTheme: 'getCurrentTheme' }),
    shouldShowHeader() {
      return Object.keys(this.currentUser).length !== 0 && !this.isRoutePublic;
    },

    isRoutePublic() {
      return this.$route.meta?.isPublic || false;
    },
  },

  beforeMount() {
    ThemeService.setNewTheme();
  },

  mounted() {
    if (!isGtmScriptOnBody()) {
      injectGtmScript();
    }
  },
};
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#app,
html,
body {
  min-height: 100vh;
  font-family: $f-base;
  background-color: $color-beige;
  transition: background-color 0.3s ease;

  &.dark-theme {
    background-color: $background-primary;
  }
}

body {
  @include scrollbar-y;
}

button {
  background: unset;
  outline: unset;
  border: unset;
}

.notices {
  z-index: 99999999999;
}
</style>
