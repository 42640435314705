import { RoutesNames } from '@/constants/routes';

export default {
  computed: {
    menuLeaderRoutes() {
      return [
        {
          id: 1,
          route: RoutesNames.AccessManagement,
          name: this.$t('header.access'),
          icon: 'icon-ic_gear',
          permission: 'ROLE_ACCESS_MANAGEMENT',
        },
        {
          id: 2,
          route: RoutesNames.AppointedLeadership,
          name: this.$t('header.projects'),
          icon: 'icon-ic_user_search',
          permission: 'ROLE_ACCESS_MANAGEMENT',
        },
        {
          id: 3,
          route: RoutesNames.TeamManagement,
          name: this.$t('header.team_management'),
          icon: 'icon-ic_users',
          permission: 'ROLE_TEAM_MANAGEMENT',
        },
        {
          id: 4,
          route: RoutesNames.ManagementReports,
          name: this.$t('header.management_reports'),
          icon: 'icon-ic_management',
          permission: 'ROLE_MANAGEMENT_REPORTS',
        },
        {
          id: 5,
          route: RoutesNames.UnlockWorkPeriod,
          name: this.$t('header.work_period_release'),
          icon: 'icon-ic_lock',
          permission: 'ROLE_WORK_PERIOD_UNBLOCK',
        },
      ];
    },
    menuIlianRoutes() {
      return [
        {
          id: 6,
          route: RoutesNames.Home,
          name: this.$t('header.clock_in'),
          icon: 'icon-ic_chronos',
          permission: 'ROLE_TIME_RECORDING',
        },
        {
          id: 7,
          route: RoutesNames.WorkHoursBank,
          name: this.$t('header.overtime'),
          icon: 'icon-ic_overtime',
          permission: 'ROLE_WORK_HOURS_BANK',
        },
      ];
    },
  },
};
