import { RoutesNames } from '@/constants/routes';

export const loginRoutes = [
  {
    path: '/login',
    name: RoutesNames.Login,
    component: () => import('@/pages/login/Index.vue'),
    meta: { isPublic: true },
  },
  {
    path: '/oauth2/redirect',
    name: 'auth',
    component: () => import('@/components/shared/auth/Auth.vue'),
  },
];
