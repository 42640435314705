const gtmIframeAttributes = {
  id: 'gtm-iframe',
  src: `https://www.googletagmanager.com/ns.html?id=${process.env.VITE_CHRONOS_GTM_ID}`,
  style: 'display: none; visibility: hidden',
};

export const isGtmScriptOnBody = () => {
  const script = document.getElementById('gtm-script');

  return !!script;
};

export const injectGtmScript = () => {
  const script = document.createElement('script');
  const noscript = document.createElement('noscript');

  const gtmIframe = document.createElement('iframe');
  Object.entries(gtmIframeAttributes).forEach(([key, value]) => gtmIframe.setAttribute(key, value));

  script.id = 'gtm-script';
  script.innerHTML = `
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', ${process.env.VITE_CHRONOS_GTM_ID});`;
  noscript.appendChild(gtmIframe);

  document.body.appendChild(noscript);
  document.body.appendChild(script);
};
