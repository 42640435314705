import i18n from '@/i18n';
import BaseService from '@/services/base.service';
import { openToast } from '@/utils/toast';

const url = `${process.env.VITE_BASE_URL}/chronos/v2`;

class UserService extends BaseService {
  async fetchUsers() {
    try {
      return await this.http.get('users');
    } catch (error) {
      openToast(i18n.t('error.common_error'), 'is-danger');
    }
  }

  async detailedDates(userId, monthYear) {
    try {
      return await this.http.get(`users/${userId}/detailed-dates?month_year=${monthYear}`);
    } catch (error) {
      openToast(i18n.t('error.common_error'), 'is-danger');
    }
  }

  async fetchUsersFromTheSameLeader(leader_id, month_year) {
    try {
      const response = await this.http.get('users', {
        params: {
          leader_id,
          month_year,
        },
        baseURL: url,
      });

      return response;
    } catch (error) {
      openToast(i18n.t('error.common_error'), 'is-danger');
    }
  }

  async getUserAllocation(userId, date) {
    return await this.http.get(`users/${userId}/allocation?date=${date}`, { baseURL: url });
  }

  async updateProjectPercentage(userId, allocation, percentage) {
    try {
      const response = await this.http.put(
        `/users/${userId}/allocation`,
        {
          id: allocation,
          distribution_percentage: percentage,
        },
        { baseURL: url },
      );
      return response;
    } catch (error) {
      openToast(i18n.t('error.common_error'), 'is-danger');
    }
  }
}

export default new UserService();
