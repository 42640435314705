import qs from 'qs';

import BaseService from '@/services/base.service';

class ExceedingHoursService extends BaseService {
  async fetchUsersExceedingHoursRequests(month_year, leader_id, user_id) {
    return this.http.get('exceeding-hours', {
      params: {
        month_year,
        leader_id,
        ...(user_id ? { user_id } : []),
      },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  }

  async updateTimeRequest(id, status) {
    return this.http.put(`exceeding-hours/${id}`, { status });
  }
}

export default new ExceedingHoursService();
