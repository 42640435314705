import Vue from 'vue';

import * as MutationTypes from './mutation-types';

import AdminService from '@/services/admin-service/admin-service';
import { underLoadingFunction } from '@/store/helpers/under-loading';

export default {
  state: {
    profiles: [],
    positions: [],
    functionalities: [],
    isPositionsLoading: false,
    totalPages: 0,
    positionsTotalRegistries: 0,
  },

  mutations: {
    [MutationTypes.SET_PROFILES](state, payload) {
      state.profiles = payload;
    },

    [MutationTypes.SET_POSITIONS](state, payload) {
      state.positions = payload;
    },

    [MutationTypes.SET_FUNCTIONALITIES](state, payload) {
      state.functionalities = payload;
    },

    [MutationTypes.ACCESSES_TOTAL_PAGES](state, payload) {
      state.totalPages = payload;
    },

    [MutationTypes.SET_POSITIONS_TOTAL_REGISTRIES](state, payload) {
      state.positionsTotalRegistries = payload;
    },

    [MutationTypes.UPDATE_POSITION](state, position) {
      let index = state.positions.findIndex((p) => p.id === position.id);
      Vue.set(state.positions, index, position);
    },

    [MutationTypes.SET_POSITIONS_LOADING](state, payload) {
      state.isPositionsLoading = payload;
    },
  },

  actions: {
    accessesSetProfiles: async ({ commit, state }) => {
      const response = await AdminService.getProfiles();
      commit(MutationTypes.SET_PROFILES, [...state.profiles, ...response.data]);
    },

    accessesSetFunctionalities: async ({ commit, state }) => {
      const response = await AdminService.getFunctionalities();
      commit(MutationTypes.SET_FUNCTIONALITIES, [...state.functionalities, ...response.data]);
    },

    accessesSetPositions: async ({ commit, state }, { page, size, name, profiles }) => {
      try {
        commit(MutationTypes.SET_POSITIONS_LOADING, true);
        const response = await AdminService.getPositions(page, size, name, profiles);

        commit(MutationTypes.ACCESSES_TOTAL_PAGES, response.data['total_pages']);
        commit(MutationTypes.SET_POSITIONS_TOTAL_REGISTRIES, response.data['total-registries']);
        commit(MutationTypes.SET_POSITIONS, [...state.positions, ...response.data.content]);
      } finally {
        commit(MutationTypes.SET_POSITIONS_LOADING, false);
      }
    },

    accessesUpdatePositions: underLoadingFunction(async ({ commit }, { profiles, positionId }) => {
      const response = await AdminService.updatePositions(profiles, positionId);
      commit(MutationTypes.UPDATE_POSITION, response.data);
    }),

    resetPositions: ({ commit }) => {
      commit(MutationTypes.SET_POSITIONS, []);
    },
  },

  getters: {
    getProfiles(state) {
      return state.profiles;
    },

    getPositions(state) {
      return state.positions;
    },

    getFunctionalities(state) {
      return state.functionalities;
    },

    getAccessesTotalPages(state) {
      return state.totalPages;
    },

    getPositionsTotalRegistries(state) {
      return state.positionsTotalRegistries;
    },

    isPositionsLoading(state) {
      return state.isPositionsLoading;
    },
  },
};
