import { RoutesNames } from '@/constants/routes';

export const managementReportsRoutes = [
  {
    path: '/management-reports',
    name: RoutesNames.ManagementReports,
    component: () => import('@/pages/managementReports/IndexV2.vue'),
    meta: { authorize: ['ROLE_MANAGEMENT_REPORTS'] },
  },
];
