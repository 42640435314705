import { THEMES } from '@/store/modules/theme-store';

const THEME_KEY = 'theme';

class ThemeService {
  getTheme() {
    return localStorage.getItem(THEME_KEY);
  }

  setNewTheme(newTheme) {
    const userTheme = this.getTheme();

    if (!userTheme) {
      localStorage.setItem(THEME_KEY, THEMES.LIGHT);
      return;
    }

    if (newTheme) {
      localStorage.setItem(THEME_KEY, newTheme);
      return;
    }
  }
}

export default new ThemeService();
