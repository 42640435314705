import BaseService from '@/services/base.service';

class PositionsService extends BaseService {
  constructor() {
    super('positions');
  }

  getPositions(params) {
    return this.get(params);
  }

  updatePositions(profiles, positionId) {
    return this.put(profiles, `${positionId}/profiles`);
  }
}

export default new PositionsService();
