import { RoutesNames } from '@/constants/routes';

export const appointedLeadershipRoutes = [
  {
    path: '/leadership-appointment',
    name: RoutesNames.AppointedLeadership,
    component: () => import('@/pages/appointedLeadership/Index.vue'),
    meta: { authorize: ['ROLE_USER'] },
  },
];
