import { isEmpty } from 'lodash';

import http from '@/config/http';

class BaseService {
  constructor(resource) {
    this.http = http;
    this.resource = resource;
  }

  get(params) {
    let request = this.resource;
    let options;

    if (typeof params === 'string' || typeof params === 'number') {
      // Ex. GET /resource/featured
      request = `${this.resource}/${params}`;
    } else {
      // Ex. GET /resource?featured=1&active=1
      options = { params };
    }

    return this.http.get(request, options);
  }

  patch(body) {
    return this.http.patch(this.resource, body);
  }

  post(body, route, config) {
    let resource = this.resource;

    if (!isEmpty(route)) {
      resource = `${this.resource}/${route}`;
    }

    return this.http.post(resource, body, config);
  }

  put(body, route) {
    let resource = this.resource;

    if (!isEmpty(route)) {
      resource = `${this.resource}/${route}`;
    }

    return this.http.put(resource, body);
  }

  delete(params) {
    let request = this.resource;
    let options;

    if (typeof params === 'string') {
      request = `${this.resource}/${params}`;
    } else {
      options = { params };
    }

    return this.http.delete(request, options);
  }
}

export default BaseService;
