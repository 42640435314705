import * as MutationTypes from './mutation-types';

import OvertimeService from '@/services/overtime-service/overtime.service';
import { underLoadingFunction } from '@/store/helpers/under-loading';

export default {
  state: {
    overtime: {
      total: {},
      dateFilter: {},
      events: [],
      closingEvents: [],
      totalPages: 0,
    },
  },
  mutations: {
    [MutationTypes.SET_TOTAL_OVERTIME](state, payload) {
      state.overtime.total = payload;
    },
    [MutationTypes.SET_DATE_FILTER](state, payload) {
      state.overtime.dateFilter = payload;
    },
    [MutationTypes.SET_OVERTIME_EVENTS](state, payload) {
      state.overtime.events = payload;
    },
    [MutationTypes.SET_OVERTIME_CLOSING_EVENTS](state, payload) {
      state.overtime.closingEvents = payload;
    },
    [MutationTypes.SET_OVERTIME_EVENTS_PAGE](state, payload) {
      state.overtime.totalPages = payload;
    },
  },
  actions: {
    totalOvertimeAction: underLoadingFunction(async ({ commit }, payload) => {
      const response = await OvertimeService.getTotalOvertime(payload);
      commit(MutationTypes.SET_TOTAL_OVERTIME, response.data);
    }),

    overtimeSetEvents: underLoadingFunction(async ({ commit, state }, { id, month_year, page, size }) => {
      const response = await OvertimeService.getOvertimeEvents(id, month_year, page, size);

      commit(MutationTypes.SET_OVERTIME_EVENTS_PAGE, response.data['total-pages']);
      commit(MutationTypes.SET_OVERTIME_EVENTS, [...state.overtime.events, ...response.data.content]);
    }),

    resetOvertimeEvents: ({ commit }) => {
      commit(MutationTypes.SET_OVERTIME_EVENTS, []);
    },
  },
  getters: {
    getDateFilter(state) {
      return state.overtime.dateFilter;
    },
    getTotalOvertime(state) {
      return state.overtime.total;
    },
    getOvertimeEvents(state) {
      return state.overtime.events;
    },
    getOvertimeEventDetail(state) {
      return state.overtime.closingEvents;
    },
    getOvertimeTotalPages(state) {
      return state.overtime.totalPages;
    },
  },
};
