import { RoutesNames } from '@/constants/routes';

export const accessManagementRoutes = [
  {
    path: '/access-management',
    name: RoutesNames.AccessManagement,
    component: () => import('@/pages/accessManagement/Index.vue'),
    meta: { authorize: ['ROLE_ACCESS_MANAGEMENT'] },
  },
];
