import i18n from '@/i18n';

export const ErrorCodes = {
  positionNotFound: {
    key: 'POSITION_NOT_FOUND',
    description: i18n.t('error.position_not_found'),
  },

  profileNotFound: {
    key: 'PROFILE_NOT_FOUND',
    description: i18n.t('error.profile_not_found'),
  },

  profileAlreadyExist: {
    key: 'PROFILE_ALREADY_EXISTS',
    description: i18n.t('error.profile_already_exists'),
  },

  profileNull: {
    key: 'PROFILE_LIST_MUST_NOT_BE_NULL',
    description: i18n.t('error.profile_null'),
  },

  forbidden: {
    key: 'FORBIDDEN',
    description: i18n.t('error.forbidden'),
  },

  periodNotFound: {
    key: 'WORK_CLOSED_PERIOD_NOT_FOUND',
    description: i18n.t('error.period_closure_not_found'),
  },

  notAllocatedLastWorklog: {
    key: 'NOT_ALLOCATED_LAST_WORKLOG',
  },

  projectAlreadyArchived: {
    key: 'PROJECT_ALREADY_ARCHIVED_BY_USER',
    description: i18n.t('error.project_already_deleted'),
  },

  leaderAppointmentUnrealized: {
    key: 'LEADER_APPOINTMENT_UNREALIZED',
    description: i18n.t('error.leader_appointment_unrealized'),
  },

  workClosedPeriodNotFound: {
    key: 'WORK_CLOSED_PERIOD_NOT_FOUND',
    description: i18n.t('error.work_closed_period_not_found'),
  },

  cannotUnblockPeriodWhenPeriodIsAlreadyClosed: {
    key: 'WORK_PERIOD_UNBLOCK_IS_NOT_ALLOWED_ON_CLOSING_PERIOD',
    description: i18n.t('error.unblock_period_already_closed_not_allowed'),
  },
};

export const ERROR_CODES = {
  TIMEOFF_NOT_FOUND: {
    message: 'error.time_off_not_found',
    type: 'is-danger',
  },

  POSITION_NOT_FOUND: {
    message: 'error.position_not_found',
    type: 'is-danger',
  },

  PROFILE_NOT_FOUND: {
    message: 'error.profile_not_found',
    type: 'is-danger',
  },

  PROFILE_ALREADY_EXISTS: {
    message: 'error.profile_already_exists',
    type: 'is-danger',
  },

  PROFILE_LIST_MUST_NOT_BE_NULL: {
    message: 'error.profile_null',
    type: 'is-danger',
  },

  FORBIDDEN: {
    message: 'error.forbidden',
    type: 'is-danger',
  },

  WORK_CLOSED_PERIOD_ALREADY_EXISTS: {
    message: 'error.period_closure_already_exists',
    type: 'is-danger',
  },

  WORK_CLOSED_PERIOD_NOT_FOUND: {
    message: 'error.period_closure_not_found',
    type: 'is-danger',
  },

  FUTURE_WORKLOG_IS_NOT_ALLOWED: {
    message: 'error.future_date_not_allowed',
    type: 'is-danger',
  },

  NOT_ALLOCATED_LAST_WORKLOG: {
    message: 'error.common_error',
    type: 'is-danger',
  },

  ILLEGAL_ARGUMENT: {
    message: 'error.common_error',
    type: 'is-danger',
  },

  LIMIT_DATE: {
    message: 'error.limit_date',
    type: 'is-danger',
  },

  TIME_OFF_DATE_IS_NOT_ALLOWED: {
    message: 'error.time_off_not_allowed',
    type: 'is-danger',
  },

  USER_ALREADY_HAS_PROJECT: {
    message: 'error.user_has_project',
    type: 'is-warning',
  },

  PROJECT_ALREADY_ARCHIVED_BY_USER: {
    message: 'error.project_already_deleted',
    type: 'is-warning',
  },

  LEADER_APPOINTMENT_UNREALIZED: {
    message: 'error.leader_appointment_unrealized',
    type: 'is-danger',
  },

  WORK_PERIOD_UNBLOCK_IS_NOT_ALLOWED_ON_CLOSING_PERIOD: {
    message: 'error.unblock_period_already_closed_not_allowed',
    type: 'is-danger',
  },

  WORKED_HOURS_LIMIT_EXCEEDED: {
    message: 'error.worked_hours_limit_exceeded',
    type: 'is-warning',
  },

  PROJECT_FINISHED: {
    message: 'error.project_finished',
    type: 'is-danger',
  },

  UPDATED_WORKLOGGED_LESS_THAN_ALLOCATED: {
    message: 'error.worklogged_less_allocated',
    type: 'is-danger',
  },

  DECREASE_HOURS_WHEN_HAS_EXCEEDING_HOURS: {
    message: 'error.decrease_exceeding_hours',
    type: 'is-warning',
  },

  EXCEEDING_HOURS_BIGGER_THAN_WORKED: {
    message: 'error.exceeding_hours_bigger_than_worked',
    type: 'is-warning',
  },

  DISTRIBUTION_PERCENTAGE_LIMIT: {
    message: 'error.distribution_percentage_limit',
    type: 'is-danger',
  },

  USER_PROJECT_DUPLICATE_ALLOCATION: {
    message: 'error.user_project_duplicate_allocation',
    type: 'is-danger',
  },

  INVALID_DISTRIBUTION_MINUTES: {
    message: 'error.invalid_distribution_minutes',
    type: 'is-danger',
  },

  INSUFFICIENT_WORKED_HOURS_TO_REGISTER_EXCEEDING_HOURS: {
    message: 'error.insufficientWorkedHoursToRegisterExceedingHours',
    type: 'is-danger',
  },

  PROJECT_ARCHIVED_BY_USER: {
    message: 'error.project_archived_by_user',
    type: 'is-danger',
  },

  INCORRECT_TIME_EXCEPTION: {
    message: 'error.incorrect_time',
    type: 'is-danger',
  },

  BRITISH_CLOCK_IN: {
    message: 'error.british_clock_in',
    type: 'is-danger',
  },
};
