import i18n from '@/i18n';
export const DetailedDates = {
  holiday: {
    key: 'HOLIDAY',
    name: i18n.t('date_types.holiday'),
  },

  municipal: {
    key: 'MUNICIPAL_HOLIDAY',
    name: i18n.t('date_types.municipal_holiday'),
  },

  state: {
    key: 'STATE_HOLIDAY',
    name: i18n.t('date_types.state_holiday'),
  },

  national: {
    key: 'NATIONAL_HOLIDAY',
    name: i18n.t('date_types.national_holiday'),
  },

  weekend: {
    key: 'WEEKEND',
    name: i18n.t('date_types.weekend'),
  },

  pending: {
    key: 'PENDING',
    name: i18n.t('date_types.pendency'),
  },

  overtime: {
    key: 'OVERTIME',
    name: i18n.t('date_types.alert'),
  },

  workDay: {
    key: 'WORK_DAY',
    name: i18n.t('date_types.workday'),
  },

  vacation: {
    key: 'VACATION',
    name: i18n.t('date_types.vacation'),
  },

  justifiedAbsence: {
    key: 'JUSTIFIED_ABSENCE',
    name: i18n.t('date_types.justified_absence'),
  },

  unjustifiedAbsence: {
    key: 'UNJUSTIFIED_ABSENCE',
    name: i18n.t('date_types.unjustified_absence'),
  },
};

export const DETAILED_DATES_EMPTY_STATE = {
  detailedDay: {
    date: '',
    exceeding_worked_minutes: 0,
    expected_working_minutes: 0,
    name: '',
    regularWorkedMinutes: 0,
    type: '',
    work_schedule: 0,
    workedMinutesWithTolerance: 0,
    worked_minutes: 0,
  },
  detailedWeek: {
    end_date: '',
    exceedingWorkedMinutes: 0,
    expected_working_minutes: 0,
    number: 0,
    regularWorkedMinutes: 0,
    start_date: '',
    workedMinutesWithTolerance: 0,
    worked_minutes: 0,
  },
  detailedMonth: {
    detailed_dates: [],
    detailed_weeks: [],
    end_date: '',
    expected_working_minutes: 0,
    month_year: '',
    regularWorkedMinutes: 0,
    start_date: '',
    user_id: 0,
    workedMinutesWithTolerance: 0,
    worked_minutes: 0,
  },
};

export const DAY_STATE = {
  COMPLETED: {
    class: '',
    tooltip: '',
  },
  WEEKEND: {
    class: 'weekend',
    tooltip: '',
  },
  HOLIDAY: {
    class: 'holiday',
    tooltip: 'date_types.holiday',
    type: 'is-success',
  },
  VACATION: {
    class: 'vacation',
    tooltip: 'date_types.vacation',
    type: 'is-primary',
  },
  DAY_OFF: {
    class: 'dayoff',
    tooltip: 'date_types.absence',
    type: 'is-info',
  },
  OVERTIME: {
    class: 'alert',
    tooltip: 'date_types.alert',
    type: 'is-warning',
  },
  PENDING: {
    class: 'pendency',
    tooltip: 'date_types.pendency',
    type: 'is-danger',
  },
};

export const SET_USER_DETAILED_DATES_EVENT = 'set-user-detailed-dates';
