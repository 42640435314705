
<header class="header">
  <button class="header__logo-btn" @click="handleRedirectToHome">
    <img src="@/assets/image/logo.svg" :alt="$t('shared.image_alt.logo')" class="header__logo" />
  </button>
  <div class="header__menu">
    <ul class="header__menu-routes">
      <router-link
        v-for="route in menuIlianRoutes"
        v-show="hasPermissionToAccess(route.permission)"
        :key="route.id"
        :to="{ name: route.route }"
        exact
        class="header__menu-routes-route"
      >
        <em :class="route.icon" class="header__menu-routes-icon" />
        {{ route.name }}
      </router-link>
      <ul v-show="userHasPermission" class="dropdown" @click="toggleMenu()">
        <div class="dropdown__header">
          <em class="icon-ic_menu dropdown__header__icon" />
          <h4 class="dropdown__title">{{ $t('home.menu') }}</h4>
        </div>
        <li :class="{ show: showDropdown }" class="dropdown__content">
          <router-link
            v-for="route in sortItemsByName(menuLeaderRoutes)"
            v-show="hasPermissionToAccess(route.permission)"
            :key="route.id"
            :to="{ name: route.route }"
            exact
            class="dropdown__content__links"
          >
            <em :class="route.icon" class="dropdown__content__links__icons" />
            <h4 class="dropdown__content__links__txt">{{ route.name }}</h4>
          </router-link>
        </li>
      </ul>
    </ul>
    <ul class="header__menu-user">
      <ThemeChanger />
      <li class="header__menu-user-name">
        {{ formattedName(user?.name || '') }}
      </li>
      <img
        :src="user.avatar"
        :alt="$t('shared.image_alt.avatar')"
        referrerpolicy="no-referrer"
        class="header__menu-user-image"
      />
      <SwitchLanguage />
      <button class="header__menu-user-button" @click="signOut">
        <em class="header__menu-user-icon icon-ic_logout" />
      </button>
    </ul>
  </div>
  <div class="header__menu-mobile">
    <BDropdown :mobile-modal="false" expanded aria-role="menu" position="is-bottom-left">
      <template #trigger>
        <img
          :src="user.avatar"
          :alt="$t('shared.image_alt.avatar')"
          referrerpolicy="no-referrer"
          class="header__image"
        />
      </template>
      <BDropdownItem v-for="route in menuIlianRoutes" :key="route.id" has-link>
        <router-link v-show="hasPermissionToAccess(route.permission)" :to="{ name: route.route }" exact>
          {{ route.name }}
        </router-link>
      </BDropdownItem>
      <BDropdownItem
        v-for="route in sortItemsByName(menuLeaderRoutes)"
        v-show="userHasPermission"
        :key="route.id"
        has-link
      >
        <router-link v-show="hasPermissionToAccess(route.permission)" :to="{ name: route.route }" exact>
          {{ route.name }}
        </router-link>
      </BDropdownItem>
    </BDropdown>
    <ThemeChanger />
    <SwitchLanguage />
    <button class="header__menu-user-button" @click="signOut">
      <em class="header__menu-user-icon icon-ic_logout" />
    </button>
  </div>
</header>
