import BaseService from '@/services/base.service';
class ProjectWorklogsService extends BaseService {
  constructor() {
    super('project-worklogs');
  }

  async createProjectWorklog(payload) {
    return await this.post(payload);
  }

  async updateProjectWorklog(projectWorklogId, payload) {
    return await this.put(payload, `${projectWorklogId}`);
  }

  async getProjectWorklog(projectWorklogId) {
    return await this.get(`${projectWorklogId}`);
  }
}

export default new ProjectWorklogsService();
