<template>
  <button class="theme-button" @click="toggleCurrentTheme">
    <transition name="icon-fade" mode="out-in">
      <component :is="themeIcon" class="theme-button__icon" />
    </transition>
  </button>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import DarkMode from '@/components/icons/DarkMode.vue';
import LightMode from '@/components/icons/LightMode.vue';
import { THEMES } from '@/store/modules/theme-store';

const THEME_ICON = {
  [THEMES.DARK]: DarkMode,
  [THEMES.LIGHT]: LightMode,
};

export default {
  name: 'ThemeChanger',
  components: {
    DarkMode,
    LightMode,
  },
  computed: {
    ...mapGetters({ currentTheme: 'getCurrentTheme' }),
    themeIcon() {
      return THEME_ICON[this.currentTheme];
    },
  },
  methods: {
    ...mapActions(['toggleCurrentTheme']),
  },
};
</script>

<style lang="scss" scoped>
.theme-button {
  min-width: 45px;
  max-width: 45px;
  cursor: pointer;
  border-radius: 50%;
  padding: 8px;
  border: 1px solid $color-yellow-ligth;
  transition: background-color 0.3s ease, border-color 0.3s ease, opacity 0.3s ease, transform 0.3s ease;

  .dark-theme & {
    background-color: transparent;
    border-color: $border-primary;
  }

  &:hover {
    opacity: 0.7;
    transform: translate(0, -2px);
    .dark-theme & {
      background-color: $hover-primary;
    }
  }

  &__icon {
    fill: $color-yellow-ligth;
    .dark-theme & {
      fill: $color-blue-light;
    }
  }
}

.icon-fade-enter-active,
.icon-fade-leave-active {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.icon-fade-enter,
.icon-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.icon-fade-leave-active {
  transform: translateX(-100%);
}
</style>
